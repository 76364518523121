export const CHANNELS_INFO: Record<string, Record<string, string>> = {
  "@thediaryofaceo": {
    id: "UCGq-a57w-aPwyi3pW7XLiHw",
    title: "The Diary Of A CEO",
    thumbnail:
      "https://yt3.ggpht.com/xSwK9eN4KTkDzIA0Qfv6ng_QQ1JrI_GxGFRp_97TWPaO5euI6Z1gxjNUksfktPka6b26l_zx=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@hubermanlab": {
    id: "UC2D2CMWXMOVWx7giW1n3LIg",
    title: "Andrew Huberman",
    thumbnail:
      "https://yt3.ggpht.com/5ONImZvpa9_hYK12Xek2E2JLzRc732DWsZMX2F-AZ1cTutTQLBuAmcEtFwrCgypqJncl5HrV2w=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@deepdivewithaliabdaal": {
    id: "UChfo46ZNOV-vtehDc25A1Ug",
    title: "Deep Dive with Ali Abdaal ",
    thumbnail:
      "https://yt3.ggpht.com/IznGH-Vc3Av9RDp2brSlwLhVX8m24MtMczgtcvtrC9tTRJw5sArFZQFjsleyPw57ZO0RZvk8Kg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@lewishowes": {
    id: "UCKsP3v2JeT2hWI_HzkxWiMA",
    title: "Lewis Howes",
    thumbnail:
      "https://yt3.ggpht.com/8rhlIOHQOxd-72MhkJbm9RkF36Z7B6aNrDlBDXNDnimzjIHFgB_y2m3LtknWSMX_7bCBdJWxYA=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@tkppodcast": {
    id: "UCLtTf_uKt0Itd0NG7txrwXA",
    title: "The Knowledge Project Podcast",
    thumbnail:
      "https://yt3.ggpht.com/DAXRofO0ZovlZt63VXCs29vbnLqjAe3kDYyi9GXAIJ9ez4a_hi40_JamjwwmEICbOWJ6jFq_-g=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@timferriss": {
    id: "UCznv7Vf9nBdJYvBagFdAHWw",
    title: "Tim Ferriss",
    thumbnail:
      "https://yt3.ggpht.com/OrRUxv5-jCFQMrIpDDY5Q5ugQzM4VwEGYBzbh_tik2TUUN5ViyuT-8rUjV7X-cNhFcjnv6vhaQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@jayshettypodcast.": {
    id: "UCbk_QsfaFZG6PdQeCvaYXJQ",
    title: "Jay Shetty Podcast",
    thumbnail:
      "https://yt3.ggpht.com/_cbhMUCXyXtacESy1DDO-psAYqM7YtAK5LUdOO8un73tzyvM0Ac9PJbUmc02CDwygdcpZXiH=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@mogawdatofficial": {
    id: "UCilMYYyoot7vhLn4Tinzmmg",
    title: "Mo Gawdat",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQLqpysDWQZlkEOqwhC_vn9ma0Hgz_FAsLChe0H2w=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@jordanharbingershow": {
    id: "UCGLcx_fFZ3GlLXO5anHSm9w",
    title: "The Jordan Harbinger Show",
    thumbnail:
      "https://yt3.ggpht.com/XQZ00DH3EnWpb6j8gNxIzJbIdSwVa7vW508VwfATJJd8nUgqeP-uYt-Icjm5mO-p9FUp3bDP=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@chriswillx": {
    id: "UCIaH-gZIVC432YRjNVvnyCA",
    title: "Chris Williamson",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRVB6Al3iJ-1e-DGYFwKXncLRh3o92psXPEgMnpGg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@highperformancepodcast": {
    id: "UCT4hFq01krOatjiSG7z3iag",
    title: "High Performance",
    thumbnail:
      "https://yt3.ggpht.com/TWTo9cQ9x0UG6dn9a6z-kLpPJipflO570ZiJKDx4uuzYMkZZADe_RX-sBeszbQ0EPeM6D7slIwU=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@tombilyeu": {
    id: "UCnYMOamNKLGVlJgRUbamveA",
    title: "Tom Bilyeu",
    thumbnail:
      "https://yt3.ggpht.com/lpbHYoLWbG6uBI8JHHjHyHQcLMtE5S2rtvn2BLPJYuW7peX_SC87uos1twvefay6wgBJ3DGiPA=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@allin": {
    id: "UCESLZhusAkFfsNsApnjF_Cg",
    title: "All-In Podcast",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQR2pf7CrYSNIJ7yJ0f4PQspP8Q9NKOUvTPPvuh=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@ramitsethi": {
    id: "UC7ZddA__ewP3AtDefjl_tWg",
    title: "I Will Teach You To Be Rich",
    thumbnail:
      "https://yt3.ggpht.com/4tXlSb8X8-bXEUZw68Zqt0pgC7D7Mnr__2k5WWZ6tdZuK109q3nTpZQeUVXKe4KC1v5S5Z7y6sU=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@drlauriesantos": {
    id: "UCFfUSTVKFCfXl6PVyG08zxg",
    title: "Dr. Laurie Santos",
    thumbnail:
      "https://yt3.ggpht.com/fWA3KP4kvbjbQxTSKW8zF906H-mjSxp-lSroCfhPvbsDoRjf3nCbXmnJ32BGO7D44o9ASIur=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@richroll": {
    id: "UCpjlh0e319ksmoOD7bQFSiw",
    title: "Rich Roll",
    thumbnail:
      "https://yt3.ggpht.com/UXI7ZyoQPoHhboBBUD8pscuva2dd-hlTxMjRDHVg96eoHOfiru5CFoWvkVe8pFiyvlB33_ceow=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@jackrhysider": {
    id: "UCMIqrmh2lMdzhlCPK5ahsAg",
    title: "Jack Rhysider",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT70kudl-_ElLVJCEIc3lkKKSHY3Mde8HVxAmmdPQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@lexfridman": {
    id: "UCSHZKyawb77ixDdsGog4iWA",
    title: "Lex Fridman",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRPXFF1l1EPjxogHsozet1vHM_hPaiqWCkKI8cJCG0=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@bigthink": {
    id: "UCvQECJukTDE2i6aCoMnS-Vg",
    title: "Big Think",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQZUmKfsc6_z4Ph-cwNJVbafNAdEqRZ3y0kkVdoCQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@codiesanchezct": {
    id: "UC5fI3kxC-ewZ6ZXEYgznM7g",
    title: "Codie Sanchez",
    thumbnail:
      "https://yt3.ggpht.com/oD4pxFAjfVIeA0gXVDNnWWq6jsa7MRd2aOfk0vPxbuFwwL8X6jsbVjwKQ4aLHwWEc8E5ZH0crg=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@noahkagan": {
    id: "UCF2v8v8te3_u4xhIQ8tGy1g",
    title: "Noah Kagan",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQEVrXbheezPa2SepKCNlMmOVDtLCU503YZUoNgJg=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@patflynn": {
    id: "UCGk1LitxAZVnqQn0_nt5qxw",
    title: "Pat Flynn",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT4jAWgKttmjnFGdRAebVgSPjntUobLisStW2XFfA=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@tonyrobbinslive": {
    id: "UCJLMboBYME_CLEfwsduI0wQ",
    title: "Tony Robbins",
    thumbnail:
      "https://yt3.ggpht.com/eYfvpz_EGKcpNwYlkPBhI22dL4Eia4e91FvpJYOPuclf9EtwLQCy81LFsI6448PhV7SdA6frIg=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@alexhormozi": {
    id: "UCUyDOdBWhC1MCxEjC46d-zw",
    title: "Alex Hormozi",
    thumbnail:
      "https://yt3.ggpht.com/7spVLsUWY48DEyACdgIMt8C_4v1QnAkhFtw40pl9c_ieUrQA-Q4gCw70Y2fvhyY707WX25WTyIo=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@leilahormozi": {
    id: "UCxCcu9pet4dljrBLf8R5nwA",
    title: "Leila Hormozi",
    thumbnail:
      "https://yt3.ggpht.com/o28H_0Y3-NmjfWjYIWapECdR9EJnljPHAaVDSp4iXkxoDojmYhdk-r8H_CCNXUkx4-poDWhs0n4=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@grahamstephan": {
    id: "UCV6KDgJskWaEckne5aPA0aQ",
    title: "Graham Stephan",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT9feBqh2MOFIKF8WmhCekO3iEZ-vmxW57Zsi5fWQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@andreijikh": {
    id: "UCGy7SkBjcIAgTiwkXEtPnYg",
    title: "Andrei Jikh",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQp3_sjOxdfKG4ZI3hn5svhywhpH0rNL8WanW-www=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@nateobrien": {
    id: "UCO3tlaeZ6Z0ZN5frMZI3-uQ",
    title: "Nate O'Brien",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSCW7_yDosaUn12oV6-iu4yOvKdFjfWMPRmpnblew=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@whiteboardfinance": {
    id: "UCL_v4tC26PvOFytV1_eEVSg",
    title: "Marko - WhiteBoard Finance",
    thumbnail:
      "https://yt3.ggpht.com/Y8by0sL-REIfqICbvwrHp2DFUSa_-fpyBPpZQ9ofUjtfPlU0qngZHHTc8ribYXazbV68qcbD=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@ryanscribner": {
    id: "UC3mjMoJuFnjYRBLon_6njbQ",
    title: "Ryan Scribner",
    thumbnail:
      "https://yt3.ggpht.com/4EfEWgrHhcfp3RcmMP7sHt_nQ2AFG0yHPxwoxkH1Hni_ADpmB_kuNwS1ppbKpi96YKVWSb9fgBg=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@financialeducation": {
    id: "UCnMn36GT_H0X-w5_ckLtlgQ",
    title: "Financial Education",
    thumbnail:
      "https://yt3.ggpht.com/Y16iJgJWLWwxstm9KjKbHTdV3mSZOzMF7TQTIDHROGxNsevuM32uBUppltjcQzyXdbaS14i5Nw=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@thefinancialdiet": {
    id: "UCSPYNpQ2fHv9HJ-q6MIMaPw",
    title: "The Financial Diet",
    thumbnail:
      "https://yt3.ggpht.com/x37p_UUXIPFLH4emPO1GqoJafXnQM8Yi02s7ioVXdhtBlhkYcuwIoIO85JHHX_lsTAB0qANo=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@mappedoutmoney": {
    id: "UCfRqvJFJ6LLQ62lKmOUXATw",
    title: "Nick True - MappedOutMoney",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZS_g8rLr_fgp6VrxEZptNmMe0VU8I3HFb2psCieVw=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@debtfreemillennials": {
    id: "UCvs2mwDS-ZiIeJ01kvzarbQ",
    title: "Debt Free Millennials",
    thumbnail:
      "https://yt3.ggpht.com/JcMRDpK8WectYYtev-2zs39k2ed5SQuVyE_DeE_VyGJjkFUewcUYm3ZWhw9UzowBIGNPgPDHUw=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@grantcardone": {
    id: "UCdlNK1xcy-Sn8liq7feNxWw",
    title: "Grant Cardone",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSBc7llzrOPeWNCzfOJxF30AIpvmCXOQ85-Cwc4sA=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@biggerpockets": {
    id: "UCVWDbXqQ8cupuVpotWNt2eg",
    title: "BiggerPockets",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZTN5kpSj2ij6uB4z1UfZN5mETApLORDh25dnZ0pIQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@jungernaut": {
    id: "UCQglaVhGOBI0BR5S6IJnQPg",
    title: "Brian Jung",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQMoxlX_RFuPRppYADlHi3a8gNB2UQq6UkYPNwPIQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@wealthhacker": {
    id: "UCkNgKCu9062P0CPyVoBI5sQ",
    title: "Wealth Hacker - Jeff Rose",
    thumbnail:
      "https://yt3.ggpht.com/Cdumn-GI8LMREbtf9_TJbTOVtBiKLVMtmGR6I7R4W0AO4QMT8ulmLAQs58YHlKriR9f5H5vW=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@hisandhermoney": {
    id: "UCCnXqVJZq_cD9wpycpml9LQ",
    title: "His And Her Money",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRYN0mvjIQpMqGs20iKB8cIEd7qmDSYDEzzZDcyDQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@coinbureau": {
    id: "UCqK_GSMbpiV8spgD3ZGloSw",
    title: "Coin Bureau",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQVF3G0o4MwawbBKIyvHb0zOqMgi2M6B_WOT9Oh=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@meetkevin": {
    id: "UCUvvj5lwue7PspotMDjk5UA",
    title: "Meet Kevin",
    thumbnail:
      "https://yt3.ggpht.com/qkQT5Fv5lnqW_c15f9IAsSWiIDY6DFJstZ84qhVI70hUUAXiJPkVWE_tHBDG2gwUvgRQN9VNPw=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@jordanpagefuncheaporfree": {
    id: "UCIBM8DAHoehmJ7_LSLDkB3A",
    title: "Jordan Page, FunCheapOrFree",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSR9f-sBL5IvYrMZd1Fv0HagWzaAp-j2rzBwIfnNQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@theramseyshow": {
    id: "UC7eBNeDW1GQf2NJQ6G6gAxw",
    title: "The Ramsey Show Highlights",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZS7phaXZUTHvVz-PoZb8aM1Lb4HNcV22cYgk3rw4A=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@minoritymindset": {
    id: "UCT3EznhW_CNFcfOlyDNTLLw",
    title: "Minority Mindset",
    thumbnail:
      "https://yt3.ggpht.com/5tHK-GQDOd0BiFdi1W_a8NF-hk5g565v5XqWltgnY5Myjv-MAMh04BP1iViouK0K1TWyp6SKsA=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@theplainbagel": {
    id: "UCFCEuCsyWP0YkP3CZ3Mr01Q",
    title: "The Plain Bagel",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSiEd_OxLQjZe-CG64Q6DRanbUL5cOedlIJvKLqyg=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@becleverwithyourcash": {
    id: "UC_T7G3RjQe7ghD0ikr4reuw",
    title: "Be Clever With Your Cash",
    thumbnail:
      "https://yt3.ggpht.com/pxLqqWYfjK6oW1M-xRNw4uaQXzKeDtwN7itJTtEcuzJM_9MdbrvNm8cUae2Qj0v-x6GkzdPD=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@johnsfinancetips": {
    id: "UCLB4e-4vO2ioj7350YeE6-g",
    title: "John Liang",
    thumbnail:
      "https://yt3.ggpht.com/M07Tt5xfTE2J__j-Rz6scTUeUNWsotuG2expy0viMTE4QDApwuyjgLLoBqRjMThms_dvxJRH=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@nischa": {
    id: "UCQpPo9BNwezg54N9hMFQp6Q",
    title: "Nischa",
    thumbnail:
      "https://yt3.ggpht.com/zkno7QTbnaWk62_ntJbnWRQ89DCy4glrtjNWYhOmQ9SQkaw2YgyKgr4vVF6uOo8_enTtDxhb=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@theschooloflifetv": {
    id: "UC7IcJI8PUf5Z3zKxnZvTBog",
    title: "The School of Life",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZTMYFPPhORFgtu2YXYB50JzmCR7Z1Ab8VJlhEvWgQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@melrobbins": {
    id: "UCk2U-Oqn7RXf-ydPqfSxG5g",
    title: "Mel Robbins",
    thumbnail:
      "https://yt3.ggpht.com/0PuR0CxjvPqBvingy5jCIIAoyTFCD48ZvI2XJa-UMlz7yhiKzfUEesmGabAM0X3BeSm6uZazOlg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@evancarmichael": {
    id: "UCKmkpoEqg1sOMGEiIysP8Tw",
    title: "Evan Carmichael",
    thumbnail:
      "https://yt3.ggpht.com/9TFeX9sxYILjmwkYZj7so4lR2BKDYuRZJlxJjrc7NJKjTOIsEbAmYVOboTo0DEVi_eJOqrW66cs=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@thomasfrank": {
    id: "UCG-KntY7aVnIGXYEBQvmBAQ",
    title: "Thomas Frank",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZQ7RjXn1-vCKywyqbQ_bga9CxBoNUxcvfSUVneGuSg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@mattdavella": {
    id: "UCJ24N4O0bP7LGLBDvye7oCA",
    title: "Matt D'Avella",
    thumbnail:
      "https://yt3.ggpht.com/ZWxpvdMOumZ2xHDnomL_asj7Zgt_4iOO7b37KIXvW-R462d0HoVSuqbttjdyYBWrHhwOVKVG_Rk=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@dr.benjaminhardy": {
    id: "UC07WXGmXVbNrv3VMOp5DvDw",
    title: "Dr. Benjamin Hardy",
    thumbnail:
      "https://yt3.ggpht.com/YBkNWiuJOsKIhRaiNvefbJiS-wd-08OSC3MfLHLNSOfWxABDJg-oURQY8ZVb_oxw1pC3k7jBEg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@brendonburchard": {
    id: "UCySH3WVP-5d4aJIfn8-WoPA",
    title: "Brendon Burchard",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSviA5u4hSxpet2v6XE7VTeZajYtOTLCn-La_ln=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@amytv": {
    id: "UCR9gMSj0UUxGvgfpNhhF3Jw",
    title: "Amy Landino",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT-CFs2VEKaPf5rJ_MNMzOb2Bovb-t_YZwzuLzFjg=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@muchelleb": {
    id: "UC0sA4gvlWFHfdwaPlBDqnGg",
    title: "muchelleb",
    thumbnail:
      "https://yt3.ggpht.com/UJkYAwuAVcMSCRfcI6VTzMLXlL-lll9cgQd0rNJtnZNfmbSp1UqJkFJAczxiOuvTNsDzN_H8PA=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@actualizedorg": {
    id: "UCgeicB5AuF3MyyUto0-M5Lw",
    title: "Actualized.org",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRTPv5Kj6aq4nAPS-C-JhjcmiU9NIORdP5pLt-5JQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@garyvee": {
    id: "UCctXZhXmG-kf3tlIXgVZUlw",
    title: "GaryVee",
    thumbnail:
      "https://yt3.ggpht.com/P6k8sraHd-wcO3HMXy_LYa4tWpavAP9v9Gh_AcD6IAFxkbk6akynEzi03EFkNrgVTaMsPV9Z=s800-c-k-c0x00ffffff-no-rj",
    category: "Business & Finance",
  },
  "@goalcast": {
    id: "UCc4IYtPKkJLSAHHuJx1GiGQ",
    title: "Goalcast",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSQlKGv3Ow2DSB30xWdGOpxmQ3kPBFgduxWIDI8SA=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@beinspiredchannel": {
    id: "UCaKZDEMDdQc8t6GzFj1_TDw",
    title: "Be Inspired",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZTwQb7IoC2QYar9hi69aTdxYo8bv80YQa9GMswjPQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@principlesbyraydalio": {
    id: "UCqvaXJ1K3HheTPNjH-KpwXQ",
    title: "Principles by Ray Dalio",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT-XXgWQHBozu9gxDiFqEXrfHOXiqxfNid59iEbrQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@edmylettshow": {
    id: "UCIprGZAdzn3ZqgLmDuibYcw",
    title: "Ed Mylett",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZR4qeaG_-b852oXagdkLQCjEXqknAyntKEWy6KPZA=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@improvementpill": {
    id: "UCBIt1VN5j37PVM8LLSuTTlw",
    title: "Improvement Pill",
    thumbnail:
      "https://yt3.ggpht.com/7XbNs1TDdQbTJYp3eVPgPnx9u-G05mBcJVx2j8mOqF0m6E2oQ36nLyH0SXvqughCEzZqjWpJ=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@theartofimprovement": {
    id: "UCtYzVCmNxrshH4_bPO_-Y-A",
    title: "The Art of Improvement",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT96kS07kB_2WGZuZzN1Hx-tdMUiRwyDbdAdk2K=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@charismaoncommand": {
    id: "UCU_W0oE_ock8bWKjALiGs8Q",
    title: "Charisma on Command",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZToAZIgeIFNkBX7MDb2PRqBlfreLoak6KTDm3AvRw=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@betterideas": {
    id: "UCtUId5WFnN82GdDy7DgaQ7w",
    title: "Better Ideas",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRs41KjX5kARk8ZSRwtTJ2oWmWrovlISMU4PADn=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@betterthanyesterday": {
    id: "UCpExuV8qJMfCaSQNL1YG6bQ",
    title: "Better Than Yesterday",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZTs34wm2roY5DqMxyHtfYu_E9Y_XJwReoNfUx6dAQ=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@jeffsu": {
    id: "UCwAnu01qlnVg1Ai2AbtTMaA",
    title: "Jeff Su",
    thumbnail:
      "https://yt3.ggpht.com/FGRGJWhnadrqjQMrNbgjff3re4aVyHcOxCfc1_tYZvfd2SCWFrS11mkUR-LyD28P1bqZICTq=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@theinfographicsshow": {
    id: "UCfdNM3NAhaBOXCafH7krzrA",
    title: "The Infographics Show",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRfHgdwyoprqqphkAoyQVGjKWysTKX8oreH3qCiVw=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@psych2go": {
    id: "UCkJEpR7JmS36tajD34Gp4VA",
    title: "Psych2Go",
    thumbnail:
      "https://yt3.ggpht.com/BltR1cUsO-aMzH-gkdlf5Buqt7s0fM60yqGiFFyEwiIHjn-IWESwv485hvK38OAPGEvAPTWIAg=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
  "@dailystoic": {
    id: "UCkUaT0T03TJvafYkfATM2Ag",
    title: "Daily Stoic",
    thumbnail:
      "https://yt3.ggpht.com/RjImzyCYScR7M_P5H1OAmGhugkXbhjku92eMrjjqSWwoDwhS4Ds8bgWnMnsxAxFCMBCQQVSC=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@keepproductive": {
    id: "UCYyaQsm2HyneP9CsIOdihBw",
    title: "Keep Productive ",
    thumbnail:
      "https://yt3.ggpht.com/7MuHINaeZuHzP8XHhxNArVtuUVk4KYU9uS_wLtNA7V7jpR6MPkS7AWVlWJ0aeDoZ0otci1l6l94=s800-c-k-c0x00ffffff-no-rj",
    category: "Personal Development",
  },
  "@thematthewhussey": {
    id: "UC9HGzFGt7BLmWDqooUbWGBg",
    title: "Matthew Hussey",
    thumbnail:
      "https://yt3.ggpht.com/AWc8JHsJDpF0FaP8ylNgMwE_1z1kl65YfQ5_n51ZyUzmii742bPMu78SQzoVVn9KOwRdjOb7sSA=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@thegottmaninstitute": {
    id: "UCnT1R08f7FHLab3nB4f0AGQ",
    title: "The Gottman Institute",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZRu7XO6uCWGLCp0FmIDhvjzTulw03NGw2PU6iZF=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@theholisticpsychologist": {
    id: "UCtEWTaMjqOH8J1Gy06Ey0Yg",
    title: "The Holistic Psychologist",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSr9d_Ba_UxPNF9EDY1jJXZsuJoE-Bim9zEF-pDCw=s800-c-k-c0x00ffffff-no-rj",
    category: "Health & Wellness",
  },
  "@reallifelore": {
    id: "UCP5tjEmvPItGyLhmjdwP7Ww",
    title: "RealLifeLore",
    thumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZSr08rlTfI3Zq5lYPK_292TJEMdpgYgQg0Ql0kW3Q=s800-c-k-c0x00ffffff-no-rj",
    category: "Science & Education",
  },
};
